import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import {PaletteOptions} from "@material-ui/core/styles/createPalette";
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme, CssBaseline, responsiveFontSizes} from "@material-ui/core";

const darkPalette: PaletteOptions = {
    background: {
        default: '#0E1E25',
        paper: '#0C1A20'
    },
    primary: {
        main: '#172933',
        light: '#658097',
        dark: '#517E9E',
        contrastText: '#c5d2d9',
    },
    secondary: {
        main: '#519E96',
        light: '#519E96',
        dark: '#519E96',
        contrastText: '#C2F7ED',
    },
    error: {
        main: '#8F5259',
        light: '#C96A77',
        dark: '#8F5259'
    },
    text: {
        primary: '#EEF0F1',
    },
    type: "dark",
};

// setup theme
let theme = createMuiTheme({
    palette: darkPalette,
    overrides: {
        MuiStepIcon: {
            root: {
                '&$completed': {
                    color: '#2fd7be',
                },
                '&$active': {
                    color: '#17665a',
                },
            },
            active: {},
            completed: {},
        },
        MuiFormLabel: {
            root: {
                "&$focused": {
                    color: "#519E96"
                }
            }
        }
    }
});
theme = responsiveFontSizes(theme);
theme.typography.h5 = {
    fontSize: '1.6rem',
    '@media (min-width:600px)': {
        fontSize: '1.7rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.8rem',
    },
};
theme.typography.h5 = {
    fontSize: '1.4rem',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.7rem',
    },
};
theme.typography.body1 = {
    fontSize: '1.3rem',
    '@media (min-width:600px)': {
        fontSize: '1.3rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.4rem',
    },
};
let rootElement = document.getElementById('root');
if (rootElement && rootElement.hasChildNodes()) {
    ReactDOM.render(
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                    <App/>
            </ThemeProvider>
        </BrowserRouter>,
        rootElement
    );
} else {
    ReactDOM.hydrate(
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                    <App/>
            </ThemeProvider>
        </BrowserRouter>,
        rootElement
    );

}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
