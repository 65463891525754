import React from 'react';
import logo from './logo.svg';
import './App.css';
import Helmet from "react-helmet";
import GenAppBar from './components/GenAppBar';
import Routes from "./Routes";

function App() {
  return (
      <div className="App">
        <Helmet title={"Generations Commercial Cleaning"}>
        </Helmet>
        <GenAppBar/>
        <Routes/>
      </div>
  );
}

export default App;
