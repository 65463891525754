import React from "react";
import {AppBar, Button, createStyles, Grid, makeStyles, Menu, MenuItem, Theme, Toolbar, IconButton} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import logo from "../generations_logo.svg";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            alignItems: 'center',
            top: 0,
            position: 'fixed',
            width: '100%',
            zIndex: 3
        },
        shadow: {
            height: '100px',
            width: '100%',
        },
        menu: {
            height: "100%",
            borderRadius: "initial",
            width: '100%',
            // float: 'right',
            // marginRight: '10px'
        },
        menuIcon: {
            width: '2rem',
            height: '2rem',
        },
        menuOptions: {
            width: '300px',
        },
        appBar: {
            height: '100px'
        },
        toolBar: {
            margin: 'auto 0',
        },
        rightAlign: {
            height: '100%',
            // float: 'right',
            margin: 'auto 0',
            paddingRight: '30px',
            paddingLeft: '30px',
            fontSize: '22px',
        },
        logo: {
            maxWidth: '200px',
            width: '100%',
        },
        hideSmall: {
            [theme.breakpoints.down(650)]: {
                display: "none",
            }
        },
        showSmall: {
            [theme.breakpoints.up(650)]: {
                display: "none",
            }
        },
    }),
);

const GenAppBar: React.FC<any> = (props: any) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const handleMenuClick = (func: () => void) => {
        handleClose();
        func();
    }
    const handleServicesClick = () => {
        let services = document.getElementById("services");
        if (services) {
            window.scroll({top: services.offsetTop - 100, behavior: "smooth"});
        }
    };

    const handleContactClick = () => {
        let contact = document.getElementById("contact");
        if (contact) {
            window.scroll({top: contact.offsetTop - 100, behavior: "smooth"});
        }
    };

    const handleLogoClick = () => {
        window.scroll({top: 0, behavior: "smooth"});
    };

    return (
        <React.Fragment>
            <div className={classes.shadow}/>
            <div className={classes.root}>
                <AppBar className={classes.appBar} position={"static"}>
                    <Toolbar className={classes.toolBar}>
                        <Grid container>
                            <Grid item xs={6} md={6}>
                                <Button color={"secondary"} style={{textTransform: 'none'}} onClick={handleLogoClick}>
                                    <img className={classes.logo} src={logo} alt={"Generations Commercial Cleaning"}/>
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={6} className={classes.hideSmall}>
                                <Button color={"secondary"} style={{textTransform: 'none'}}
                                        className={classes.rightAlign} onClick={handleServicesClick}>
                                    Services
                                </Button>
                                <Button color={"secondary"} style={{textTransform: 'none'}}
                                        className={classes.rightAlign} onClick={handleContactClick}>
                                    Contact
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={6} className={classes.showSmall}>
                                <IconButton
                                    color={"secondary"} style={{textTransform: 'none'}} aria-controls="simple-menu"
                                    aria-label="more"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className={classes.menu}
                                >
                                    <MoreVertIcon className={classes.menuIcon} />
                                </IconButton>
                                {/*<Button color={"secondary"} style={{textTransform: 'none'}} aria-controls="simple-menu"*/}
                                {/*        className={classes.rightAlign} onClick={handleClick}>*/}
                                {/*    menu*/}
                                {/*</Button>*/}
                                <Menu
                                    className={classes.menuOptions}
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={(event: any) => handleMenuClick(handleLogoClick)}>Top</MenuItem>
                                    <MenuItem onClick={(event: any) => handleMenuClick(handleServicesClick)}>Services</MenuItem>
                                    <MenuItem onClick={(event: any) => handleMenuClick(handleContactClick)}>Contact</MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
        </React.Fragment>
    )
};

export default GenAppBar;
