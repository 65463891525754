import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./containers/Home";

const Routes: React.FC = (props: any) =>
    <Switch>
        <Route path="/" exact component={Home}/>
        { /* Finally, catch all unmatched routes */ }
        <Route component={Home} />
    </Switch>;

export default Routes;

