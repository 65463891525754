import React from "react";
import {makeStyles, createStyles, Theme, Typography, Container, Grid, Button} from "@material-ui/core";
import './home.css';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hero: {
            width: '100%',
            height: 'calc(100vh - 100px)',
            backgroundImage: 'url(images/hero.jpeg)',
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 1
        },
        services: {
            marginTop: '10px',
            textAlign: 'center',
        },
        service: {
            paddingLeft: '20px',
            paddingRight: '20px',
        },
        contact: {},
        container: {
            padding: '0 50px',
        },
        spaceTop: {
            paddingTop: '10px'
        }
    }),
);

const Home: React.FC<any> = (props: any) => {
    const classes = useStyles();

    return (
        <div id={"home"}>
            <div className={classes.hero}>
                <div className={'hero-blurb'}>
                    <Typography variant={"h4"}>
                      Are you tired of complaining to your mediocre cleaning company?
                    </Typography>

                    <Typography variant={"h4"}>
                      Are you ready for <span><em>professional</em></span> quality and peace of mind?
                    </Typography>

                    <Typography variant={"h4"}>
                      We can help! 
                    </Typography>

                    <Typography variant={"h4"}>
                    <Button>
                        <a href="tel:706-424-3403">
                            {"Call us"}
                        </a>
                    </Button>
                    </Typography>

                    <Typography className={'caption'} variant={"caption"}>
                        Proudly serving the metro Athens, GA area. (Go Dawgs!)
                    </Typography>
                  {/* <Typography variant={"h4"}>*/}
                  {/*</Typography>*/}
                </div>
            </div>
            <div id="hero-shadow"/>
            <Container className={classes.services} maxWidth={"xl"} id={'services'}>
                <Typography variant={"h4"} align={"center"}>Services</Typography>
                <Grid container>
                    <Grid item md={4} sm={6} xs={12}>
                        <span className={classNames({["fa-stack"]: true, ["fa-4x"]: true })}>
                            <i className={classNames({
                                ["icon-cleaning"]: true,
                                ["fa-stack-1x"]: true,
                                ["fa-inverse"]: true,
                            })}/>
                        </span>
                        <Typography variant={"h5"}>Commercial Cleaning</Typography>
                        <Typography variant={"body1"}><strong>Generations Commercial Cleaning</strong> provides comprehensive building maintenance programs. From our nightly cleaning programs, to our scheduled floor maintenance programs; we will keep your facility sanitary and pristine. </Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <span className={classNames({["fa-stack"]: true, ["fa-4x"]: true })}>
                            <i className={classNames({
                                ["icon-cleaning_cart"]: true,
                                ["fa-stack-1x"]: true,
                                ["fa-inverse"]: true,
                            })}/>
                        </span>
                        <Typography variant={"h5"}>Floor Maintenance</Typography>
                        <Typography variant={"body1"} className={classes.service}> <strong>Generations Floor Maintenance</strong> provides cleaning and maintenance programs for all kinds of floors, including but not limited to: carpet, terrazzo, VCT, tile/grout, and concrete.</Typography>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <span className={classNames({["fa-stack"]: true, ["fa-4x"]: true })}>
                            <i className={classNames({
                                ["fas"]: true,
                                ["fa-trash"]: true,
                                ["fa-stack-1x"]: true,
                                ["fa-inverse"]: true,
                            })}/>
                        </span>
                        <Typography variant={"h5"}>Trash Concierge</Typography>
                        <Typography variant={"body1"}> <strong>Generations Concierge Waste Collection</strong> takes pride in providing high-standard waste collection services that satisfy residents and property managers alike. We value enduring relationships based on mutual trust. We strive at all times to provide the best waste collection service possible.</Typography>
                    </Grid>
                </Grid>
            </Container>
            <div className={classes.contact} id={'contact'}>
                <Container className={classes.container} maxWidth={"xl"} id={'contact'}>
                    <Typography variant={"h4"} align={"center"}>Contact Us</Typography>
                    <Typography variant={"body1"}>
                        {"For a quote or questions about our services please feel free to contact us "} <strong>{"by phone "}</strong> {"at "}
                        <a href="tel:706-424-3403">
                            {"706-424-3403"}
                        </a>
                        {" or "} <strong> {"by email "} </strong> {"at "}
                        <a href="mailto:gencomcleaning@gmail.com">
                            {"gencomcleaning@gmail.com"}
                        </a>!
                    </Typography>
                </Container>
            </div>
        </div>
    )
};

export default Home;
